const React = require('react');
const useSelector = require('react-redux').useSelector;

const NoteActions = require('client/redux/actions/note');

/* eslint-disable react/jsx-no-bind */
const Notes = React.memo((
  {
    notes,
    notableType,
    notableId,
    employeeId
  }
) => {
  const text = useSelector(state => state.notes.text);

  const changeNote = (event) => {
    NoteActions.changeNote(event.target.value);
  };

  const createNote = (event) => {
    if (event.key !== 'Enter') return;

    NoteActions.create({
      attributes: {
        text,
        notable_type: notableType,
        notable_id: notableId,
        user_id: employeeId
      }
    });
  };

  const deleteNote = (id, event) => {
    event.preventDefault();
    return NoteActions.delete({ id });
  };

  return (
    <div className="notes">
      <input
        type="text"
        placeholder={I18n.t('javascripts.new_note')}
        value={text}
        onChange={changeNote}
        onKeyPress={createNote}
      />

      {notes.map(note => (
        <div className="note" key={note.id}>
          <div className="controls">
            <a
              href="#"
              className="delete"
              onClick={deleteNote.bind(null, note.id)}
            >
              <i aria-hidden="true" className="fa-solid fa-trash"/>
            </a>
          </div>
          <div className="text">
            <p>
              {note.text}
            </p>
          </div>
          <span className="user">
            {note.employee_name}
          </span>
          <div className="timeago">
            {window.moment(note.created_at).format('DD-MM-YYYY HH:mm')}
          </div>
        </div>
      ))}
    </div>
  );
});
/* eslint-enable react/jsx-no-bind */

Notes.displayName = 'Notes';

module.exports = Notes;
