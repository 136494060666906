const React = require('react');
const createReactClass = require('create-react-class');

const Margin = require('client/lib/margin');
const Popup = require('client/react/components/popup');

const SwapStockConfirmation = createReactClass({
  componentDidMount () {
    const oldMargin = Margin.getValue(this.props.oldStock);
    const newMargin = Margin.getValue(this.props.newStock);

    if (newMargin > oldMargin) {
      this.handleCheckMargin();
    }
  },

  componentDidUpdate (nextProps) {
    if (nextProps.currentMargin != null) {
      if (!this.margin) {
        this.margin = new Margin(nextProps.currentMargin, nextProps.customerMargin, nextProps.marginExceedPercentage);
      }
      this.margin.set(nextProps.currentMargin, nextProps.customerMargin, nextProps.marginExceedPercentage);
    } else {
      this.margin = undefined;
    }
  },

  handleCheckMargin () {
    return this.props.onCheckMargin(this.props.planning.order_id);
  },

  handleIncreaseMargin () {
    this.margin.increase();
    return this.forceUpdate();
  },

  handleConfirm () {
    this.props.onConfirm(this.props.planning, this.props.newStock);
  },

  handleCancel () {
    this.props.onCancel();
  },

  render () {
    let warning;
    let actions = (
      <div className="buttons">
        <a className="button button_no" onClick={this.handleCancel}>
          {I18n.t('javascripts.cancel')}
        </a>
        <a className="button green button_yes" onClick={this.handleConfirm}>
          {I18n.t('javascripts.ok')}
        </a>
      </div>
    );

    if (this.props.inProgress) {
      actions = (
        <div className="buttons">
          <span className="notice">
            <i className="rotating fa fa-circle-o-notch" />
            <span>{I18n.t('javascripts.rebooking')}</span>
          </span>
        </div>
      );
    }

    if (this.props.failed) {
      const error = this.props.error.message || I18n.t('javascripts.rebooking_not_possible');

      actions = (
        <div className="buttons">
          <span className="notice error" style={{ color: 'red' }}>
            <i className="fa fa-warning" />
            <span>{error}</span>
          </span>
          <a className="button button_no" onClick={this.handleCancel}>{I18n.t('javascripts.ok')}</a>
        </div>
      );
    }

    if (this.props.checkingMargin) {
      actions = (
        <div className="buttons">
          <span className="notice">
            <i className="rotating fa fa-circle-o-notch" />
            <span>{I18n.t('javascripts.margin_check')}</span>
          </span>
        </div>
      );
    }

    if (this.margin && this.margin.exceeds()) {
      if (!this.props.checkingMargin) {
        actions = (
          <div className="buttons">
            <a className="refresh-margin" onClick={this.handleCheckMargin}>
              {I18n.t('javascripts.margin_check_retry')}
            </a>
            <a className="button button_no" onClick={this.handleCancel}>
              {I18n.t('javascripts.cancel')}
            </a>
            <a className="button button_yes disabled">
              {I18n.t('javascripts.ok')}
            </a>
          </div>
        );
      }

      if (!this.margin.canIncrease() || this.margin.exceedsMax()) {
        warning = (
          <div className="box warning">
            {I18n.t('javascripts.product_exceeds_margin')}
            <hr />
            <span className="current-margin">
              {accounting.formatMoney(this.margin.current / 100)} / {accounting.formatMoney(this.margin.allowed / 100)}
            </span>
            <a className="button" href={`/customers/${this.props.customerID}/margins`} target="_blank" rel="noreferrer">
              {I18n.t('javascripts.swap_stock_confirmation.edit_margin')}
            </a>
          </div>
        );
      } else {
        warning = (
          <div className="box gray">
            {I18n.t('javascripts.product_exceeds_margin')}
            <hr />
            <span className="current-margin">
              {accounting.formatMoney(this.margin.current / 100)} / {accounting.formatMoney(this.margin.allowed / 100)}
            </span>
            <a className="button" onClick={this.handleIncreaseMargin}>
              {I18n.t('javascripts.swap_stock_confirmation.increase_once')}
            </a>
          </div>
        );
      }
    }

    return (
      <Popup
        style="v2"
        className="error-popup swap-stock-popup"
        shown
      >
        <div className="content">
          <div className="title">
            {I18n.t('javascripts.rebook_product')}
          </div>
          <i className="fa fa-arrows-v" />
          <div className="old-stock">
            <strong>#{this.props.oldStock.number}</strong>
            {this.props.oldStock.name}
          </div>
          <div className="new-stock">
            <strong>#{this.props.newStock.number}</strong>
            {this.props.newStock.name}
          </div>
          {warning}
        </div>
        {actions}
      </Popup>
    );
  }
});

const mapStateToProps = (state, props) => {
  return {
    planning: state.orm.plannings.getById(props.planningID),
    oldStock: state.orm.stocks.getById(props.oldStockID),
    newStock: state.orm.stocks.getById(props.newStockID)
  };
};

module.exports = ReactRedux.connect(mapStateToProps)(SwapStockConfirmation);
