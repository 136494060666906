const React = require('react');
const createReactClass = require('create-react-class');

const employeeSelectors = require('client/react/selectors/employee');
const PhoneClientActions = require('client/redux/actions/phone_client');
const EmployeeActions = require('client/redux/actions/employee');
const PhoneCallActions = require('client/redux/actions/phone_call');
const Timer = require('client/react/components/timer');

/* eslint-disable react/jsx-no-bind */
const Redirecter = createReactClass({
  componentDidMount () {
    return this.props.fetchOnlineEmployees();
  },

  render () {
    return (
      <div>
        <div id="phone-call-redirector">
          <input
            type="text"
            onChange={this.props.onChangeQuery}
            placeholder={I18n.t('javascripts.phone_bar.filter_placeholder')}
            value={this.props.query}
          />

          <ul className="list scrollable">
            {
              this.props.groupedEmployees.map((group, team) => (
                <li key={team} className="header">
                  <span className="header">{team}</span>
                  <ul>
                    {
                      group.map(employee => {
                        const inPhoneCallClass = employee.phone_call_busy_at ? 'in-call' : '';

                        return (
                          <li
                            key={employee.id}
                            className={inPhoneCallClass}
                          >
                            <a
                              className="button small"
                              href="#"
                              onClick={this.props.redirectToQueue.bind(null, employee.identity)}
                            >
                              {I18n.t('javascripts.phone_bar.connect')}
                            </a>

                            {employee.phone_call_busy_at && (
                              <div className="timer">
                                <Timer startTime={new Date(employee.phone_call_busy_at)}/>
                              </div>
                            )}

                            <span
                              className="employee-name clickable"
                              onClick={this.props.redirectToQueue.bind(null, employee.identity)}
                            >
                              {employee.name}
                            </span>

                            {employee.phone_call_busy_at && (
                              <span className="in-call">
                                ({I18n.t('javascripts.phone_bar.call_busy')})
                              </span>
                            )}
                          </li>
                        );
                      })
                    }
                  </ul>
                </li>
              ))
            }
          </ul>

          <div className="buttons">
            <a
              className="button full red"
              onClick={this.props.cancel}
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
    );
  }
});
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, props) => {
  const query = state.phoneClient.redirectQuery;

  let employees = employeeSelectors.redirectable(state.orm.employees, {
    employeeId: props.employeeId
  });
  employees = employeeSelectors.sortedByName(employees);
  employees = employeeSelectors.search(employees, state.phoneClient.redirectQuery);
  const groupedEmployees = employees.toList().groupBy(employee => {
    return employee.team || I18n.t('javascripts.phone_bar.no_team');
  });

  return {
    groupedEmployees,
    employees,
    query
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    cancel () {
      return PhoneClientActions.setStatus('busy');
    },

    fetchOnlineEmployees () {
      return EmployeeActions.fetchOnline();
    },

    redirectToQueue (queue) {
      return PhoneCallActions.redirectToQueue({
        CallSid: props.phoneCall.sid,
        queue
      });
    },

    onChangeQuery (event) {
      return PhoneClientActions.setRedirectQuery(event.target.value);
    }
  };
};

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Redirecter);
