const React = require('react');
const createReactClass = require('create-react-class');

const Popup = require('client/react/components/popup');

// Trigger for a popup.
// There are two callbacks that close the popup from inside given component:
//   => onCancel()
//   => onComplete()
//
// @example
//   <PopupTrigger component={someComponent}>
//     <a>Do something!</a>
//   </PopupTrigger>
//
const PopupTrigger = createReactClass({
  getInitialState () {
    return { shown: false };
  },

  handlePopupClickOutside () {
    if (this.state.shown) {
      return this.handleHide();
    }
  },

  handleTriggerClick () {
    if (this.state.shown) {
      return this.handleHide();
    } else {
      return this.handleShow();
    }
  },

  handleHide () {
    // eslint-disable-next-line react/no-is-mounted
    if (this.isMounted() && this.state.shown) {
      $('body').removeClass('stop-scrolling');
      this.setState({ shown: false });
    }
  },

  handleShow () {
    this.props.onTrigger?.();

    // eslint-disable-next-line react/no-is-mounted
    if (this.isMounted() && !this.state.shown) {
      $('body').addClass('stop-scrolling');
      this.setState({ shown: true });
    }
  },

  renderPopup () {
    const props = {
      // Hide the popup when onCancel is called
      onCancel: () => {
        this.props.component.props.onCancel?.(...arguments);
        return this.handleHide();
      },
      // Hide the popup when onComplete is called
      onComplete: () => {
        this.props.component.props.onComplete?.(...arguments);
        return this.handleHide();
      }
    };

    return (
      <Popup
        onClickOutside={this.handlePopupClickOutside}
        shown={this.state.shown}
      >
        {React.cloneElement(this.props.component, props)}
      </Popup>
    );
  },

  render () {
    const props = {
      ref: el => {
        this.trigger = el;
      },
      onClick: this.handleTriggerClick
    };

    return (
      <>
        {React.cloneElement(this.props.children, props)}
        {this.renderPopup()}
      </>
    );
  }
});

module.exports = PopupTrigger;
