const React = require('react');
const moment = require('moment');

const HighlightPeriod = React.memo((
  {
    order,
    periodFrom,
    periodTill,
    from,
    till
  }
) => {
  const left = Utils.Calendar.calculatePeriodOffset(periodFrom, periodTill, moment(from));
  const right = Utils.Calendar.calculatePeriodOffset(periodFrom, periodTill, moment(till));
  const width = right - left;

  const style = {
    left: left + '%',
    width: width + '%'
  };

  const pickupDepot = depots[order.pickup_depot_id];
  const returnDepot = depots[order.return_depot_id];

  const fromClasses = `from text-color ${pickupDepot.color_class}`;
  const tillClasses = `till text-color ${returnDepot.color_class}`;

  return (
    <div className="gantt-select-period" style={style}>
      <div className="timestamps">
        <span className={fromClasses}>
          {Utils.Calendar.dateFormat(from, 'time')} {pickupDepot.code}
        </span>
        <span className={tillClasses}>
          {returnDepot.code} {Utils.Calendar.dateFormat(till, 'time')}
        </span>
      </div>
    </div>
  );
});

HighlightPeriod.displayName = 'HighlightPeriod';

module.exports = HighlightPeriod;
