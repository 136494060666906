const moment = require('moment');
const _ = require('lodash/fp');

const StockPlanningActions = require('./stock_planning_actions');
const ProductAccessoriesActions = require('./ProductAccessoriesActions').default;
const productActions = require('./product');

const CalendarActions = {
  initialize (month) {
    store.dispatch({
      type: 'CALENDAR_INITIALIZE',
      payload: {
        month
      }
    });

    if (month) {
      const date = moment(month);
      this.setMonth(date);
      this.centerOnDate(date);
    }
  },

  setZoom (zoom) {
    store.dispatch({
      type: 'CALENDAR_SET_ZOOM',
      payload: {
        zoom
      }
    });
  },

  setMonth (date) {
    const from = moment(date).startOf('month');
    const till = from.clone().add(1, 'month').endOf('month');
    this.setPeriod(from, till);
  },

  setPeriod (from, till) {
    store.dispatch({
      type: 'CALENDAR_SET_PERIOD',
      payload: {
        from,
        till
      }
    });

    this.fetchData(true);
  },

  setFilters (params) {
    store.dispatch({
      type: 'CALENDAR_SET_FILTERS',
      payload: {
        query: params.query,
        filters: params.filters,
        product_type: params.product_type
      }
    });

    this.fetchData();
  },

  setAutocompleteQuery (query) {
    store.dispatch({
      type: 'CALENDAR_SET_AUTOCOMPLETE_QUERY',
      payload: {
        autocompleteQuery: query
      }
    });
  },

  setAutocompleteResults (ids, stock = []) {
    const payload = stock
      ? { autocompleteIds: ids, cartAccessoriesIds: stock.optionalAccessoryIds }
      : { autocompleteIds: ids };

    store.dispatch({
      type: 'CALENDAR_SET_AUTOCOMPLETE_RESULTS',
      payload
    });
  },

  filterByProduct (stock) {
    const calendarData = {
      query: stock.name,
      filters: { ...store.getState().calendar.filters, product_id: stock.product_id },
    };

    this.setAutocompleteQuery(stock.name);

    productActions.autocomplete({ query: stock.name }).then((response) => {
      const productIds = response.payload.normalizedResponse.result.products;
      this.setAutocompleteResults(productIds, stock);
    });

    this.setFilters(calendarData);

    store.dispatch({
      type: 'NO_SALE_FIND_ALTERNATIVE',
      payload: {
        id: null
      }
    });
  },

  centerOnDate (date) {
    store.dispatch({
      type: 'CALENDAR_CENTER_ON_DATE',
      payload: {
        date
      }
    });
  },

  prevPeriod () {
    const from = store.getState().calendar.from;
    const centerOn = Utils.Calendar.viewingNow('center').subtract(4, 'days');

    if (Utils.Calendar.viewingNow('left').diff(from, 'days') === 0) {
      this.setMonth(from.clone().subtract(1, 'month'));
    }

    this.centerOnDate(centerOn);
  },

  nextPeriod () {
    const till = store.getState().calendar.till;
    const centerOn = Utils.Calendar.viewingNow('center').add(4, 'days');

    if (Utils.Calendar.viewingNow('right').diff(till, 'days') === 0) {
      this.setMonth(till.clone().add(1, 'month'));
    }

    this.centerOnDate(centerOn);
  },

  moreStock () {
    const state = store.getState().calendar;
    if (state.lastPage) return;

    store.dispatch({
      type: 'CALENDAR_MORE_STOCK',
      payload: {}
    });

    this.fetchData();
  },

  visuallySwap (planning, stock) {
    store.dispatch({
      type: 'CALENDAR_VISUALLY_SWAP',
      payload: {
        planningID: planning.id,
        oldStockID: planning.stock_id,
        newStockID: stock.id
      }
    });
  },

  askSwap (planning, stock) {
    store.dispatch({
      type: 'CALENDAR_ASK_SWAP',
      payload: {
        planningID: planning.id,
        oldStockID: planning.stock_id,
        newStockID: stock.id
      }
    });
  },

  cancelSwap (planning) {
    const state = store.getState().calendar;

    store.dispatch({
      type: 'CALENDAR_CANCEL_SWAP',
      payload: {
        planningID: state.swapStock.planningID || planning?.id
      }
    });
  },

  confirmSwap (planning, stock) {
    StockPlanningActions.swap({ id: createId(), planning, stock });
  },

  fetchOrderMargin (orderID) {
    store.dispatch({
      type: 'CALENDAR_FETCH_ORDER_MARGIN_START',
      payload: {
        orderID
      }
    });

    request.get(`/reservations/${orderID}/margin`).then((response) => {
      store.dispatch({
        type: 'CALENDAR_FETCH_ORDER_MARGIN_SUCCESS',
        payload: {
          orderID,
          data: response.data
        }
      });
    });
  },

  fetchData (periodChanged = false) {
    const state = store.getState();
    const calendarState = state.calendar;

    if (calendarState.loading) return false;

    const params = {
      from: calendarState.from.format(),
      till: calendarState.till.format(),
      per_page: calendarState.per_page,
      page: calendarState.page,
      order_id: state.planner.orderID,
      in_a_bag: false,
      show_in_planner: true,
      q: calendarState.query, // eslint-disable-line id-length
      search: calendarState.filters,
      product_type: calendarState.product_type
    };

    if (periodChanged) {
      // Period is changed, fetch all data for all stock
      params.per_page = calendarState.page * calendarState.per_page;
      params.page = 1;
    }

    if (!_.isEmpty(params.search.product_id) || !_.isEmpty(params.q)) {
      params.order_id = null;
    }

    this.fetchStockData(params);
  },

  fetchStockData (params = {}) {
    store.dispatch({
      type: 'CALENDAR_FETCH_STOCK_DATA_START',
      payload: {
        params
      }
    });

    request.get('/api/1/stocks', { params }).then((response) => {
      const data = Model.Stock._normalizeResponse({ data: response.data });
      const total_count = response.data.meta.total_count;
      const isLastPage = total_count
        ? total_count <= params.per_page * params.page
        : response.data.stocks.length < params.per_page;

      store.dispatch({
        type: 'CALENDAR_FETCH_STOCK_DATA_SUCCESS',
        payload: {
          params,
          normalizedResponse: data,
          lastPage: isLastPage
        }
      });

      this.fetchTransportData({
        from: params.from,
        till: params.till,
        stock_ids: data.result.stocks
      });
      this.fetchProductAccessoryData({ stock_ids: data.result.stocks });
    });
  },

  fetchTransportData (params = {}) {
    store.dispatch({
      type: 'CALENDAR_FETCH_TRANSPORT_DATA_START',
      payload: {
        params
      }
    });

    request.get('/reports/transports/planner', { params }).then((response) => {
      const data = Model.Transport._normalizeResponse({ data: { transports: response.data } });

      store.dispatch({
        type: 'CALENDAR_FETCH_TRANSPORT_DATA_SUCCESS',
        payload: {
          params,
          normalizedResponse: data
        }
      });
    });
  },

  fetchProductAccessoryData (params = {}) {
    const state = store.getState();
    const stocks = state.orm.stocks.getAll({ id: params.stock_ids });

    if (state.planner.orderID && stocks.size() > 0) {
      const product_ids = _.uniq(stocks.pluck('product_id'));
      ProductAccessoriesActions.fetchAll({ product_ids });
    }
  }
};

module.exports = CalendarActions;
