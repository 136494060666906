const React = require('react');
const createReactClass = require('create-react-class');

const History = createReactClass({
  render () {
    if (this.props.phoneCalls.size()) {
      return (
        <div id="phone-call-history">
          <ul className="list">
            {
              this.props.phoneCalls.map(phoneCall => {
                const direction = phoneCall.direction === 'inbound' ? 'left' : 'right';

                return (
                  <li key={phoneCall.id}>
                    <div className="timer">
                      {window.moment(phoneCall.created_at).startOf('seconds').fromNow()}
                    </div>
                    <i className={`fa fa-arrow-${direction}`}/>
                    <div className="info">
                      <span className="employee-name">
                        {phoneCall.employee_name || I18n.t('javascripts.phone_bar.no_answer')}
                      </span>
                      <span className="phone-number">
                        {phoneCall.number}
                      </span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="cleanstate">
          <p>
            {I18n.t('javascripts.phone_bar.no_customer_history')}
          </p>
        </div>
      );
    }
  }
});

const mapStateToProps = (state, props) => {
  const customer = state.orm.customers.getById(props.phoneCall?.customer);
  let phoneCalls = state.orm.phone_calls.getById((customer?.phone_calls) || []);
  phoneCalls = phoneCalls.filter(phoneCall => phoneCall.id !== props.phoneCall.id);

  return {
    phoneCalls
  };
};

module.exports = ReactRedux.connect(mapStateToProps)(History);
