const React = require('react');
const createReactClass = require('create-react-class');

const PhoneClientActions = require('client/redux/actions/phone_client');
const Timer = require('client/react/components/timer');

/* eslint-disable react/jsx-no-bind */
const WrapUp = createReactClass({
  render () {
    return (
      <div id="call-controls">
        <div id="call-timer">
          <Timer />
        </div>
        <div className="buttons">
          <a
            className="button full"
            style={{ marginTop: '8px' }}
            onClick={this.props.wrappedUp.bind(null, this.props.employeeId, this.props.callSid)}
          >
            <i className="fa fa-check"/> {I18n.t('javascripts.phone_bar.ready')}
          </a>
        </div>
      </div>
    );
  }
});
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, _props) => {
  const client = state.phoneClient;

  return {
    employeeId: client.employee_id,
    callSid: client.callSid
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    wrappedUp (employeeId, callSid) {
      return PhoneClientActions.wrappedUp(employeeId, callSid);
    }
  };
};

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(WrapUp);
