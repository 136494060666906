import 'trix';
import '@rails/actiontext';

require('./initializer/turbo');
require('./globals');

window.Model = require('client/redux/models/models');
window.Utils = require('client/utils/utils');
window.API = Utils.API;
window.store = require('client/redux/store');

require('./react/initializer');
require('../shared/channels');
