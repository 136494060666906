const React = require('react');
const createReactClass = require('create-react-class');
const moment = require('moment');
const _ = require('lodash');

const monthpickerActions = require('client/redux/actions/monthpicker');

const Monthpicker = createReactClass({
  previous () {
    if (this.props.selectedMonth !== 0) {
      return this.props.setMonth(null, this.props.selectedMonth - 1);
    } else {
      this.props.setMonth(null, 12);
      return this.props.setYear(null, this.props.selectedYear - 1);
    }
  },

  next () {
    if (this.props.selectedMonth !== 11) {
      return this.props.setMonth(null, this.props.selectedMonth + 1);
    } else {
      this.props.setMonth(null, 0);
      return this.props.setYear(null, this.props.selectedYear + 1);
    }
  },

  render () {
    return (
      <div className="month_picker_input">
        <i className="prev fa-solid fa-caret-left" aria-hidden="hidden" onClick={this.previous} />

        <select
          id="month_month"
          value={this.props.selectedMonth}
          className="monthpicker"
          onChange={this.props.setMonth}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(i => (
            <option key={i} value={i}>
              {moment().month(i).format('MMMM')}
            </option>
          ))}
        </select>

        <select
          id="month_year"
          value={this.props.selectedYear}
          className="yearpicker"
          onChange={this.props.setYear}
        >
          {this.props.yearRange.map(i => (
            <option key={i} value={i}>{i}</option>
          ))}
        </select>

        <i className="select fa-solid fa-calendar-days" aria-hidden="hidden" />
        <i className="next fa-solid fa-caret-right" aria-hidden="hidden" onClick={this.next} />
      </div>
    );
  }
});

const mapStateToProps = (state, props) => {
  const currentYear = moment().year();

  return {
    selectedYear: state.monthpicker.selectedYear,
    selectedMonth: state.monthpicker.selectedMonth,
    value: state.monthpicker.value,
    currentYear,
    yearRange: _.range(currentYear - 10, currentYear + 11)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setMonth (event, value) {
      const month = event?.target.value || value;
      const monthpickerValue = store.getState().monthpicker.value;
      const newValue = monthpickerValue.month(month);

      monthpickerActions.setMonth(month, newValue);
      return props.onChange(newValue);
    },

    setYear (event, value) {
      const year = event?.target.value || value;
      const monthpickerValue = store.getState().monthpicker.value;
      const newValue = monthpickerValue.year(year);

      monthpickerActions.setYear(year, newValue);
      return props.onChange(newValue);
    }
  };
};

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Monthpicker);
