const React = require('react');
const useState = React.useState;
const useCallback = React.useCallback;
const ReactSelect = require('react-select').default;

const StockPlanningActions = require('client/redux/actions/stock_planning_actions');

const DestroyPickedupPlanning = React.memo((
  {
    planning,
    stock,
    depots,
    onCancel
  }
) => {
  const [selectedDepot, setSelectedDepot] = useState(null);

  const changeDepot = useCallback((value) => {
    setSelectedDepot(value);
  }, []);

  const destroyPlanning = useCallback(() => {
    StockPlanningActions.delete({
      id: planning.id,
      depot_id: selectedDepot.value
    }).then(onCancel);
  }, [planning, selectedDepot, onCancel]);

  const buttonClass = selectedDepot ? 'enabled' : 'disabled';

  return (
    <div className="destroy-pickedup-planning">
      <h3>
        {I18n.t('javascripts.planner.stock_not_in_depot', { stock_name: stock.name })}
      </h3>
      <p>
        {I18n.t('javascripts.planner.select_depot_to_return')}
      </p>
      <div className="form">
        <div className="select">
          <ReactSelect
            options={depots}
            value={selectedDepot}
            onChange={changeDepot}
          />
        </div>
        <a
          className={`button red ${buttonClass}`}
          onClick={destroyPlanning}
          data-testid="destroy-planning"
        >
          {I18n.t('javascripts.destroy')}
        </a>
      </div>
    </div>
  );
});

DestroyPickedupPlanning.displayName = 'DestroyPickedupPlanning';

module.exports = DestroyPickedupPlanning;
