import _ from 'lodash/fp';

const groupPlanningsByProductId = _.flow(
  _.filter('product_id'),
  _.groupBy('product_id'),
  _.mapValues((childrenGroup) => ({
    ...childrenGroup[0], // Keep the first child as the base
    qty: childrenGroup.length
  }))
);

export { groupPlanningsByProductId };
