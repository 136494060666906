const React = require('react');
const { createRoot } = require('react-dom/client');
const { Provider } = require('react-redux');

const getComponents = require('shared/getComponents').default;
const RealtimeActions = require('client/redux/actions/realtime_actions');
const PhoneBar = require('./scenes/phone_bar/phone_bar');
const CalendarScene = require('./scenes/calendar/calendar');
const PlannerScene = require('./scenes/calendar/planner'); // eslint-disable-line no-unused-vars
const PlannerActions = require('./scenes/calendar/planner/actions.jsx').default;
const Tagger = require('./components/tagger');
const ExportButton = require('./components/export_button');
const CallbackRequest = require('./components/callback_request_generator');
const OrderPickupReturn = require('client/react/components/OrderPickupReturn').default; // eslint-disable-line no-unused-vars

const context = require.context('client/react/components', false, /^.*\.jsx$/);

const availableComponents = getComponents(context);
let renderedRoots = [];

// Renders given component when element exists.
//
// @example
//   render(PhoneBar, document.getElementById('phone-bar'))
//
const render = (Component, element, props = {}, unmount = true) => {
  if (element && element.getAttribute('react') !== 'loaded') {
    const root = createRoot(element);
    const reactComponent = (props) => (
      <Provider store={store}>
        <Component {...props} root={root} />
      </Provider>
    );

    const reactElement = React.createElement(reactComponent, props);
    root.render(reactElement);

    if (unmount) {
      renderedRoots.push(root);
    }

    element.setAttribute('react', 'loaded');
  }
};

const initializePhoneBar = function () {
  if (!window.initializedPhoneBar && window.envType !== 'test') {
    // Use PhoneBar only for Budgetcam
    if ($('body').data().companyId !== 1) {
      return;
    }

    // Initialize PhoneBar in the main tab only
    if (isMainTab()) {
      const link = '<link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%221.2em%22 font-size=%2270%22>📞</text></svg>">';
      document.getElementsByTagName('head')[0].insertAdjacentHTML('afterend', link);

      $('.body-container').addClass('has-phone-bar');

      if ($('.body-container').hasClass('has-phone-bar')) {
        render(PhoneBar, document.getElementById('phone-bar-wrapper'), {}, false);
        window.initializedPhoneBar = true;
      }
    }
  } else {
    $('.body-container').removeClass('has-phone-bar');
  }
};

window.onunload = function () {
  if (localStorage.activeTab === window.tabID) {
    return localStorage.removeItem('activeTab');
  }
};

window.isMainTab = () => {
  window.tabID ||= createId();
  localStorage.activeTab ||= window.tabID;
  return localStorage.activeTab === window.tabID;
};

window.initializeReactComponents = () => {
  return $(() => {
    // Dynamic way of rendering React components
    let calendarElement, plannerActionsElement;
    $('.react-component').each((i, element) => {
      const data = $(element).data();
      let reactComponent = availableComponents[data.component];
      reactComponent ||= eval(data.component); // eslint-disable-line no-eval

      if (reactComponent) {
        if (data?.props?.orm) {
          store.dispatch({ type: 'NEW_DATA', payload: { response: data.props.orm } });
          delete data.props.orm;
        }

        render(reactComponent, element, data.props);
      }
    });

    // Initialize Calendar
    if ((calendarElement = $('.calendar-scene')).length) {
      render(CalendarScene, calendarElement[0], calendarElement.data().react);
    }

    // Initialize Tagger
    $('.tagger').each((i, el) => {
      const taggerElement = $(el);
      render(Tagger, taggerElement[0], taggerElement.data().react);
    });

    // Initialize Export buttons
    $('.export-button').each((i, el) => {
      const exportElement = $(el);
      const data = JSON.parse(exportElement.attr('data')).react;
      render(ExportButton, exportElement[0], data);
    });

    $(document).on('click', '.new-callback-request', () => {
      render(CallbackRequest, $('#react-popup')[0], $(this).data());
    });

    if ((plannerActionsElement = $('#planner-actions')).length) {
      const data = JSON.parse(plannerActionsElement.attr('data-react'));
      render(PlannerActions, plannerActionsElement[0], data);
    }
  });
};

document.addEventListener('turbo:before-visit', () => {
  renderedRoots.forEach(root => root.unmount());
  renderedRoots = [];
});

const updateLayoutAndInitReact = () => {
  if ($('#phone-bar').length > 0) {
    $('.body-container').addClass('has-phone-bar');
  } else {
    $('.body-container').removeClass('has-phone-bar');
  }

  window.initializeReactComponents();
};

document.addEventListener('turbo:load', updateLayoutAndInitReact);
document.addEventListener('turbo:render', updateLayoutAndInitReact);

document.addEventListener('DOMContentLoaded', () => {
  RealtimeActions.initialize();
  initializePhoneBar();

  // Format settings
  accounting.settings = lodash.merge(accounting.settings, {
    currency: {
      symbol: '€',
      decimal: ',',
      thousand: '.'
    },
    number: {
      precision: 0,
      decimal: ',',
      thousand: '.'
    }
  });
});
