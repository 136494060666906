const React = require('react');
const createReactClass = require('create-react-class');

const PhoneClientActions = require('client/redux/actions/phone_client');
const EmployeeDialer = require('./employee_dialer');
const { twilioCall } = require('./utils');

/* eslint-disable react/jsx-no-bind */
const Dialer = createReactClass({
  render () {
    const togglerClass = this.props.showEmployeeDialer ? 'open' : '';

    return (
      <div id="phone-bar-dialer">
        <a className="button blue phone-home"
          onClick={this.props.dial.bind(null, '+3197010259643')}
        >
          <i className="fa fa-phone"/>
          {I18n.t('javascripts.phone_bar.call_ehbp')}
        </a>
        <div className="input">
          <a
            className={`button ${togglerClass}`}
            onClick={this.props.toggleEmployeeDialer}
          >
            <i className="fa fa-user"/>
          </a>
          <input
            type="text"
            placeholder={I18n.t('javascripts.phone_bar.phone_number_placeholder')}
            value={this.props.number} onChange={this.props.changeNumber}
            onKeyPress={this.props.onKeyPress}
          />
        </div>
        <a
          className="button call"
          onClick={this.props.dial.bind(null, this.props.number)}
        >
          <i className="fa fa-phone"/>
          {I18n.t('javascripts.phone_bar.call')}
        </a>
        {this.props.showEmployeeDialer && <EmployeeDialer/>}
      </div>
    );
  }
});
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, _props) => {
  return {
    number: state.phoneClient.number,
    showEmployeeDialer: state.phoneClient.showEmployeeDialer
  };
};

const mapDispatchToProps = (_dispatch, _props) => {
  return {
    onKeyPress (event) {
      if (event.key === 'Enter') {
        return twilioCall({ To: store.getState().phoneClient.number });
      }
    },

    dial (number) {
      return twilioCall({ To: number });
    },

    changeNumber (event) {
      return PhoneClientActions.setNumber(event.target.value);
    },

    toggleEmployeeDialer () {
      return PhoneClientActions.toggleEmployeeDialer(!store.getState().phoneClient.showEmployeeDialer);
    }

  };
};

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Dialer);
