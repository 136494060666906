const React = require('react');
const createReactClass = require('create-react-class');
const _ = require('lodash');

const OpeningTimesUtils = require('client/utils/OpeningTimes');

const DAYS_PER_WEEK = 7;
const HOURS_PER_DAY = 24;

const Units = createReactClass({
  displayName: 'Units',
  mixins: [PureAwesomeMixin],

  fetchSubUnits (date, type) {
    switch (type) {
    case 'months':case 'month':
      date = date.clone().startOf('month').startOf('day');

      return _.range(date.daysInMonth()).map(interval => {
        const _date = date.clone().add(interval, 'days');
        return { label: Utils.Calendar.dateFormat(_date, 'day'), date: _date };
      });
    case 'weeks':
    case 'week':
      return _.range(DAYS_PER_WEEK).map(interval => {
        const _date = date.clone().add(interval, 'days');
        return { label: Utils.Calendar.dateFormat(_date, 'day'), date: _date };
      });
    case 'days':
    case 'day':
      return this.dayUnits || (this.dayUnits = _.range(HOURS_PER_DAY).map(interval => {
        const time = moment(`1-1-2001 ${interval}:00`);
        return { label: Utils.Calendar.dateFormat(time, 'time'), date };
      }));
    }
  },

  dateClosed (date) {
    if (!this.props.order) {
      return false;
    }

    if (this.props.openingTimes) {
      const pickupDepot = window.depots[this.props.order.pickup_depot_id];
      const returnDepot = window.depots[this.props.order.return_depot_id];

      const pickupDepotClosed = !OpeningTimesUtils.isDayOpen(date, pickupDepot, this.props.openingTimes);
      const returnDepotClosed = !OpeningTimesUtils.isDayOpen(date, returnDepot, this.props.openingTimes);

      return pickupDepotClosed || returnDepotClosed;
    } else {
      // Closed every sunday
      return date.day() === 0;
    }
  },

  dateWeekend (date) {
    return date.day() === 0 || date.day() === 6;
  },

  renderSubUnits (date) {
    return this.fetchSubUnits(date, this.props.type).map(unit => {
      const overlapClass = [];

      if (this.dateClosed(unit.date)) {
        overlapClass.push('closed');
      }
      if (this.dateWeekend(unit.date)) {
        overlapClass.push('weekend');
      }

      return <div className={`sub-unit ${overlapClass.join(' ')}`} key={unit.label}><span>{unit.label}</span></div>;
    });
  },

  renderUnits () {
    const from = moment(this.props.from);
    const till = moment(this.props.till);

    const periodCount = till.diff(from, this.props.type);
    return _.range(periodCount + 1).map(i => {
      const _from = from.clone().add(i, this.props.type).startOf(this.props.type);

      return (
        <div className="units" key={_from.unix()}>
          <div className={this.props.type}>
            <div className="unit">
              <span>
                {Utils.Calendar.dateFormat(_from, this.props.type)}
              </span>
              <div className="sub-units">
                {this.renderSubUnits(_from)}
              </div>
            </div>
          </div>
        </div>
      );
    });
  },

  render () {
    return <div>{this.renderUnits()}</div>;
  }
});

module.exports = Units;
