const React = require('react');
const createReactClass = require('create-react-class');
const { createRoot } = require('react-dom/client');
const Provider = require('react-redux').Provider;

// Detached component for rendering a global component outside the current tree.
//
// @example
//   <DetachedComponent>
//     <Popover>
//       Look at me, im cool!
//     </Popover>
//   </DetachedComponent>
//
const DetachedComponent = createReactClass({
  componentWillUnmount () {
    setTimeout(() => {
      this.root.unmount();
    });

    if (document.body.contains(this.container)) {
      document.body.removeChild(this.container);
    }
  },

  componentDidMount () {
    this.container = document.createElement('div');
    this.root = createRoot(this.container);
    document.body.appendChild(this.container);

    this.renderOrUpdate();
  },

  componentDidUpdate () {
    this.renderOrUpdate();
  },

  renderOrUpdate () {
    const children = this.props.children || <div />;
    return this.root.render(<Provider store={store}>{children}</Provider>);
  },

  render () {
    return false;
  }
});

module.exports = DetachedComponent;
