import React, { Component } from 'react';
import { connect as reduxConnect } from 'react-redux';
import moment from 'moment';

import Popup from 'client/react/components/popup';

class BookError extends Component {
  static displayName = 'BookError';

  get title () {
    switch (this.props.error.actionType) {
    case 'ORDERS_UPDATE_ERROR':
      return I18n.t('javascripts.book_error.order_update');
    default:
      return I18n.t('javascripts.book_error.book_stock');
    }
  }

  get description () {
    const { error, depots }  = this.props;

    switch (error.actionType) {
    case 'ORDERS_UPDATE_ERROR': {
      const attributes = error.options.attributes;
      const pickupDepot = depots.getById(attributes.pickup_depot_id);
      const returnDepot = depots.getById(attributes.return_depot_id);

      return (
        <div>
          <p>
            {moment(attributes.pickup_at).format('HH:mm DD-MM-YY')} {pickupDepot.name}
            <i className="fa fa-arrow-right" />
            {moment(attributes.return_at).format('HH:mm DD-MM-YY')} {returnDepot.name}
          </p>
          <hr />
        </div>
      );
    }
    default:
      return null;
    }
  }

  render = () => {
    const { error, stocks, metadata, show, onHide } = this.props;

    return (
      <Popup style="v2" className="error-popup" shown={show} onClickOutside={onHide}>
        <div className="content">
          <div className="title">{this.title}</div>

          {this.description}

          {stocks.map((stock) => {
            return (
              <div key={stock.id} className="stock">
                {metadata && metadata[stock.id] && <span className="metadata">{metadata[stock.id]}</span>}
                <strong>#{stock.number}</strong> {stock.name}
              </div>
            );
          })}
        </div>
        <div className="buttons">
          <span className="notice error">
            <i className="fa fa-warning" /> <span>{error.message}</span>
          </span>
          <a className="button button_no" onClick={onHide}>{I18n.t('javascripts.ok')}</a>
        </div>
      </Popup>
    );
  };
}

const mapStateToProps = (state, props) => {
  return {
    stocks: props.stocks || state.orm.stocks.getAll({ id: props.error.options.attributes.stock_id }),
    depots: state.orm.depots
  };
};

export default reduxConnect(mapStateToProps)(BookError);
