// React
import React, { Component } from 'react';
import { connect as reduxConnect } from 'react-redux';

class OrderMargin extends Component {
  static displayName = 'OrderMargin';

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { margin } = this.props;

    const currentMargin = Utils.Format.money(margin.current);
    const customerMargin = Utils.Format.money(margin.customer);

    return (
      <ul className="divider specs">
        <li className="attribute">
          <span className="spec">{I18n.t('javascripts.total')}:</span>
          <span>{currentMargin}</span>
        </li>
        <li className="attribute">
          <span className="spec">{I18n.t('javascripts.margin')}:</span>
          <span>{customerMargin}</span>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    margin: state.planner.margin
  };
};

const mapDispatchToProps = (state, props) => {
  return {};
};

export default reduxConnect(mapStateToProps, mapDispatchToProps)(OrderMargin);
