import consumer from './consumer';
import RealtimeActions from '../../client/redux/actions/realtime_actions';

if (window.appType === 'Roots') {
  consumer.subscriptions.create('PhoneCallsChannel', {
    received (data) {
      RealtimeActions.dispatch(data.event, Model.PhoneCall, data.data);
    }
  });
}

if (window.appType === 'Metrics') {
  consumer.subscriptions.create('PhoneCallsChannel', {
    received () {
      Turbo.visit(window.location, { action: 'replace' });
    }
  });
}
