import './callback_requests_channel';
import './employees_channel';
import './orders_channel';
import './phone_calls_channel';
import './stocks_channel';
import './stock_plannings_channel';
import './stock_print_channel';
import './transports_channel';

import consumer from './consumer';
window.consumer = consumer;
