import React, { Component } from 'react';
import { connect as reduxConnect } from 'react-redux';
import moment from 'moment';

import BookError from './BookError';

class NoRoutesAvailableError extends Component {
  static displayName = 'NoRoutesAvailableError';

  render = () => {
    return (
      <BookError {...this.props} />
    );
  };
}

const mapStateToProps = (state, props) => {
  const stockIds = [];
  const metadata = {};

  props.error.data.transports.forEach((transport) => {
    stockIds.push(transport.stock_id);

    if (transport.first_available_at) {
      metadata[transport.stock_id] = `${I18n.t('javascripts.book_error.first_available_date')} ${moment(transport.first_available_at).format('DD-MM-YYYY HH:mm')}`;
    }
  });

  return {
    stocks: state.orm.stocks.getAll({ id: stockIds }),
    metadata
  };
};

export default reduxConnect(mapStateToProps)(NoRoutesAvailableError);
