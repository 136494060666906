const React = require('react');
const createReactClass = require('create-react-class');

const employeeSelectors = require('client/react/selectors/employee');

const PhoneClientActions = require('client/redux/actions/phone_client');
const EmployeeActions = require('client/redux/actions/employee');
const { twilioCall } = require('./utils');

const Timer = require('client/react/components/timer');

/* eslint-disable react/jsx-no-bind */
const EmployeeDialer = createReactClass({
  componentDidMount () {
    return this.props.fetchOnlineEmployees();
  },

  render () {
    return (
      <div>
        <div id="phone-bar-employee-dialer">
          <input
            type="text"
            onChange={this.props.onChangeQuery}
            placeholder={I18n.t('javascripts.phone_bar.employee_dialer_placeholder')}
            value={this.props.query}
            autoFocus={true}
          />
          <ul className="scrollable">
            {
              this.props.groupedEmployees.map((group, team) => (
                <li key={team} className="header">
                  <span className="header">{team}</span>
                  <ul>
                    {group.map(employee => {
                      const inPhoneCallClass = employee.phone_call_busy_at ? 'in-call' : '';

                      return (
                        <li key={employee.id} className={inPhoneCallClass}>
                          <a
                            className="button small"
                            href="#"
                            onClick={this.props.dial.bind(null, employee.id)}
                          >
                            {I18n.t('javascripts.phone_bar.call')}
                          </a>

                          {employee.phone_call_busy_at && (
                            <div className="timer">
                              <Timer startTime={new Date(employee.phone_call_busy_at)}/>
                            </div>
                          )}

                          <span
                            className="employee-name clickable"
                            onClick={this.props.dial.bind(null, employee.id)}
                          >
                            <span className="employee-name">{employee.name}</span>
                            <span className="employee-team">{employee.team}</span>
                          </span>

                          {employee.phone_call_busy_at && (
                            <span className="in-call">({I18n.t('javascripts.phone_bar.call_busy')})</span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
          </ul>

          <div className="buttons">
            <a
              className="button full red"
              onClick={this.props.cancel}
            >
              {I18n.t('javascripts.cancel')}
            </a>
          </div>
        </div>
      </div>
    );
  }
});
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, props) => {
  const query = state.phoneClient.callEmployeeQuery;

  let employees = employeeSelectors.redirectable(state.orm.employees, {
    employeeId: props.employeeId
  });
  employees = employeeSelectors.sortedByName(employees);
  employees = employeeSelectors.search(employees, state.phoneClient.callEmployeeQuery);
  const groupedEmployees = employees.toList().groupBy(employee => {
    return employee.team || I18n.t('javascripts.phone_bar.no_team');
  });

  return {
    employees,
    groupedEmployees,
    query
  };
};

const mapDispatchToProps = (_dispatch, _props) => {
  return {
    fetchOnlineEmployees () {
      return EmployeeActions.fetchOnline();
    },

    onChangeQuery (event) {
      return PhoneClientActions.setCallEmployeeQuery(event.target.value);
    },

    cancel () {
      return PhoneClientActions.toggleEmployeeDialer(false);
    },

    dial (id) {
      return twilioCall({ client_id: store.getState().phoneClient.employee_id, employee_id: id });
    }
  };
};

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(EmployeeDialer);
