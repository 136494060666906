const React = require('react');
const useSelector = require('react-redux').useSelector;

const Orders = React.memo(({ phoneCall }) => {
  const orders = useSelector(state => state.orm.orders.getById(phoneCall?.orders));

  return (
    <div id="phone-call-orders">
      <div className="head">
        <h3>
          {I18n.t('javascripts.phone_bar.reservations')}
        </h3>
      </div>
      <ul className="list">
        {
          orders.map(order => (
            <li
              className="order-vcard"
              key={order.id}
              data-position="left"
              data-id={order.id}
            >
              {order.status === 'canceled'
                ? <a href={`/orders/${order.id}/edit`}>#{order.number}</a>
                : <a href={`/orders/${order.id}/planning`}>#{order.number}</a>
              }
              <span className={`order_status ${order.status}`}>
                {Inflector.humanize(order.status)}
              </span>
            </li>
          ))
        }
      </ul>
    </div>
  );
});

Orders.displayName = 'Orders';

module.exports = Orders;
