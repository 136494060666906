import consumer from './consumer';
import RealtimeActions from '../../client/redux/actions/realtime_actions';

if (window.appType === 'Roots') {
  consumer.subscriptions.create('CallbackRequestsChannel', {
    received (data) {
      RealtimeActions.dispatch(data.event, Model.CallbackRequest, data.data);
    }
  });
}
