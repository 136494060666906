const React = require('react');

const Checkbox = React.memo(({ checked, label, onChange }) => {
  const [isChecked, setChecked] = React.useState(checked || false);

  const toggle = React.useCallback(() => {
    const checked = !isChecked;
    setChecked(checked);
    onChange?.(checked);
  }, [isChecked, onChange]);

  return (
    <span onClick={toggle}>
      <input type="checkbox" checked={isChecked} readOnly={true} />
      <label>{label}</label>
    </span>
  );
});

Checkbox.displayName = 'Checkbox';

module.exports = Checkbox;
