const React = require('react');
const createReactClass = require('create-react-class');

const Timer = createReactClass({
  getInitialState () {
    return {
      startTime: this.props.startTime || Date.now(),
      time: 0
    };
  },

  componentDidMount () {
    this.tick();
    this.timer = setInterval(this.tick, 1000);
  },

  componentWillUnmount () {
    clearInterval(this.timer);
  },

  tick () {
    this.setState({ time: Date.now() - this.state.startTime });
  },

  render () {
    return <span>{Utils.ConvertTime(this.state.time)}</span>;
  }
});

module.exports = Timer;
