const React = require('react');
const useSelector = require('react-redux').useSelector;

const ReturnForms = React.memo(({ phoneCall }) => {
  const returnForms = useSelector(state => state.orm.return_forms.getById(phoneCall?.return_forms));

  const renderReturnForm = (returnForm) => {
    const reasons = [];
    if (returnForm.loss) {
      reasons.push(`${I18n.t('javascripts.phone_bar.loss')}`);
    }
    if (returnForm.damage) {
      reasons.push(`${I18n.t('javascripts.phone_bar.damage')}`);
    }
    if (returnForm.complaint) {
      reasons.push(`${I18n.t('javascripts.phone_bar.complaint')}`);
    }

    return (
      <li
        className="order-vcard"
        key={returnForm.order_id}
        data-position="left"
        data-id={returnForm.order_id}
      >
        <a href={`/reservations/${returnForm.order_id}/return_form`}>
          #{returnForm.order_number}
        </a>
        <span className="reasons">
          {reasons.length && `(${reasons.join(', ')})`}</span>
        <span className={`order_status ${returnForm.order_status}`}>
          {Inflector.humanize(returnForm.order_status)}
        </span>
      </li>
    );
  };

  return (
    <div id="phone-call-return-forms">
      <div className="head">
        <h3>
          {I18n.t('javascripts.phone_bar.open_return_forms')}
        </h3>
      </div>
      <ul className="list">
        {returnForms.map(renderReturnForm)}
      </ul>
    </div>
  );
});

ReturnForms.displayName = 'ReturnForms';

module.exports = ReturnForms;
