// Libraries
import React, { Component } from 'react';

// Components
import BookError from './BookError';
import NoRoutesAvailableError from './NoRoutesAvailableError';

const errorComponents = {
  duplicate: BookError,
  conflicting_orders_or_transport: BookError,
  no_routes_available: NoRoutesAvailableError
};

export default class PlannerError extends Component {
  static displayName = 'PlannerError';

  state = {
    show: false,
    lastErrorAt: null
  };

  componentDidUpdate = () => {
    // Check timestamp here
    if (this.state.lastErrorAt !== this.props.error.timestamp) {
      this.setState({ show: true, lastErrorAt: this.props.error.timestamp });
    }
  };

  handleHide = () => {
    this.setState({ show: false });
  };

  renderError = () => {
    const Component = errorComponents[this.props.error.code];

    if (Component) {
      return (
        <Component
          {...this.props}
          show={this.state.show}
          onHide={this.handleHide}
        />
      );
    } else {
      return null;
    }
  };

  render = () => {
    if (!this.props.error.code) {
      return null;
    } else {
      return this.renderError();
    }
  };
}
