const React = require('react');
const createReactClass = require('create-react-class');

const PhoneClientActions = require('client/redux/actions/phone_client');
const PhoneCallActions = require('client/redux/actions/phone_call');

const Timer = require('client/react/components/timer');
const CustomerSegment = require('client/react/components/customer_segment');

const phoneCallSelectors = require('client/react/selectors/phone_call');
const employeeSelectors = require('client/react/selectors/employee');

const _values = require('lodash/values');
const _flatten = require('lodash/flatten');
const _map = require('lodash/map');

/* eslint-disable react/jsx-no-bind */
const Queue = createReactClass({
  ring () {
    PhoneCallActions.forceUpdate();
    this._sound?.pause();
    return this.sound()?.play();
  },

  sound () {
    this._sound = (() => {
      switch (this.props.sound) {
      case 'team':
        return new Audio(assets.sounds.team);
      case 'overflow':
      case 'ehbp':
        return new Audio(assets.sounds.overflow);
      case 'personal':
        return new Audio(assets.sounds.personal);
      default:
        return null;
      }
    })();

    return this._sound;
  },

  componentDidMount () {
    this.ring();
    this.timer = setInterval(this.ring, 3000);
  },

  componentWillUnmount () {
    try {
      this._sound?.pause();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    this._sound = null;

    return clearInterval(this.timer);
  },

  renderPhoneCall (phoneCall, type) {
    let className, clickFunction;
    const customer = phoneCall.getCustomer();

    if (customer) {
      clickFunction = () => Turbo.visit(`/customers/${customer.id}/orders`);
      className = 'clickable';
    }

    const domain = this.props.domains.getById(phoneCall.domain_id);

    return (
      <li key={phoneCall.id}>
        {
          phoneCall.direction === 'internal'
            ? (
              <div className="badge geen">
                <span>{I18n.t('javascripts.phone_bar.internal')}</span>
              </div>
            )
            : (
              customer
                ? <CustomerSegment customer={customer}/>
                : (
                  <div className="badge icon">
                    <i className="fa fa-smile-o"/>
                  </div>
                )
            )
        }

        <div className={`content ${className}`} onClick={clickFunction}>
          <span className={`customer-name customer_status status ${customer?.status?.color}`}>
            {phoneCall.customer_name}
          </span>

          <span className="phone-number">
            {phoneCall.direction !== 'internal'
              ? phoneCall.most_called_with || I18n.t('javascripts.phone_bar.no_accountmanager')
              : I18n.t('javascripts.phone_bar.internal_call')}
          </span>

          {type !== 'personal' && phoneCall.queue && phoneCall.queue !== 'main' && (
            <span className="redirected">
              <i className="fa fa-share"/>
              {Inflector.humanize(phoneCall.queue)}
            </span>
          )}

          <span className="called-to">
            <i className="fa fa-phone"/>
            {
              phoneCall.called_to === '+3197010259643'
                ? I18n.t('javascripts.phone_bar.ehbp')
                : phoneCall.called_to}
          </span>
        </div>

        <div className="action">
          <div className="timer">
            <Timer startTime={new Date(phoneCall.created_at)}/>
          </div>

          {phoneCall.direction === 'internal' && (
            <a
              className="button red"
              onClick={this.props.hangup.bind(null, phoneCall.sid)}
            >
              <i className="fa fa-phone hangup"/>
            </a>
          )}
          <a
            className="button"
            onClick={this.props.takeFromQueue.bind(null, phoneCall.sid, this.props.identity)}
          >
            <i className="fa fa-phone"/>
          </a>
        </div>

        <div className="locale-domain">
          <span className="domain">
            {domain?.name}
          </span>
          <span className="locale">
            {phoneCall.locale?.toUpperCase()}
          </span>
        </div>
      </li>
    );
  },

  renderQueues () {
    const firstCallSid = _flatten(_values(this.props.phoneCalls))[0]?.sid;

    if (!firstCallSid) {
      return (
        <div className="cleanstate">
          <p>
            {I18n.t('javascripts.phone_bar.no_waiting')}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <div className="buttons">
            <a
              className="button green full"
              onClick={this.props.takeFromQueue.bind(null, firstCallSid, this.props.identity)}
            >
              <i className="fa fa-phone"/>
              {I18n.t('javascripts.phone_bar.take_call')}
            </a>
          </div>

          {_map(this.props.phoneCalls, (phoneCalls, queue) => {
            return phoneCalls.length > 0 && (
              <div className="queue">
                <div className="head">
                  <span className="pull-right">{phoneCalls.length}</span>
                  <h3>{queue}</h3>
                </div>
                <ul className="list">
                  {phoneCalls.map(phoneCall => this.renderPhoneCall(phoneCall, queue))}
                </ul>
              </div>
            );
          })}
        </div>
      );
    }
  },

  render () {
    return (
      <div id="phone-queue">
        {
          this.props.isBusyEmployees.size() > 0 && (
            <div className="in-call-list">
              <div className="head">
                <span className="pull-right">{this.props.isBusyEmployees.size()}</span>
                <h3>{I18n.t('javascripts.phone_bar.current_calls')}</h3>
              </div>
              <ul className="list">
                {this.props.isBusyEmployees.map(employee => (
                  <li key={employee.id}>
                    <div className="timer">
                      <Timer startTime={new Date(employee.phone_call_busy_at)}/>
                    </div>
                    <span className="employee-name">{employee.name}</span>
                    <span className="employee-team">{employee.function}</span>
                  </li>
                ))}
              </ul>
            </div>
          )
        }

        {this.renderQueues()}
      </div>
    );
  }
});
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, _props) => {
  let overflow, sound;
  const client = state.phoneClient;
  const phoneCalls = state.orm.phone_calls;
  const { employees } = state.orm;
  const phoneFunction = client.function;

  const phoneCallsWithQueues = {};

  if (client.function === 'inbound' || client.function === 'overflow') {
    overflow = phoneCallSelectors.overflow(phoneCalls).toArray();
    const globalOverflow = phoneCallSelectors.globalOverflow(phoneCalls).toArray();
    phoneCallsWithQueues.overflow = overflow.concat(globalOverflow);
  }

  if (client.function === 'inbound') {
    phoneCallsWithQueues.inbound = phoneCallSelectors.inbound(phoneCalls).toArray();
  }

  if (client.function === 'ehbp') {
    phoneCallsWithQueues.ehbp = phoneCallSelectors.ehbp(phoneCalls).toArray();
  }

  phoneCallsWithQueues.personal = phoneCallSelectors.personal(phoneCalls, client.identity).toArray();

  let isBusyEmployees = employeeSelectors.redirectable(employees, client.employee_id);
  isBusyEmployees = employeeSelectors.isBusy(isBusyEmployees);
  isBusyEmployees = employeeSelectors.planning(isBusyEmployees);

  if (phoneCallsWithQueues.personal != null ? phoneCallsWithQueues.personal.length : undefined) {
    sound = 'personal';
  } else if (phoneCallsWithQueues.overflow != null ? phoneCallsWithQueues.overflow.length : undefined) {
    sound = 'overflow';
  } else if (phoneCallsWithQueues.inbound != null ? phoneCallsWithQueues.inbound.length : undefined) {
    sound = 'team';
  }

  return {
    phoneCalls: phoneCallsWithQueues,
    identity: client.identity,
    function: phoneFunction,
    isBusyEmployees,
    sound,
    domains: state.orm.domains
  };
};

const mapDispatchToProps = (_dispatch, _props) => {
  return {
    hangup (sid) {
      return PhoneCallActions.hangup({ CallSid: sid });
    },

    takeFromQueue (sid, identity) {
      PhoneClientActions.connect(sid);

      // # Make sure we recover from calls we can't connect.
      setTimeout(() => {
        if (!Twilio.Device.activeConnection()) {
          return PhoneClientActions.disconnect();
        }
      }, 5000);

      return PhoneCallActions.takeFromQueue({
        CallSid: sid,
        identity
      }).catch((data) => {
        if (data.response.status === 409) {
          Flash.alert(I18n.t('javascripts.phone_bar.reservations'));
        }
        if (data.response.status === 422) {
          Flash.alert(I18n.t('javascripts.phone_bar.someone_else_answered_conversation'));
        }
        return PhoneClientActions.disconnect();
      });
    }
  };
};

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Queue);
