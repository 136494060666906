const React = require('react');
const createReactClass = require('create-react-class');

const PlannerActions = require('client/redux/actions/planner_actions');
const StockPlanningActions = require('client/redux/actions/stock_planning_actions');

const Calendar = require('client/react/components/calendar/calendar');
const StockActions = require('./planner/stock_actions');
const HighlightPeriod = require('./planner/highlight_period');
const Error = require('./planner/Error').default;

const PlannerScene = createReactClass({

  displayName: 'PlannerScene',

  componentDidMount () {
    this.props.onMount();
  },

  componentWillUnmount () {
    this.props.onUnMount();
  },

  renderStockActions (stock) {
    return (
      <StockActions
        isPlanned={this.props.plannedStockIDs.includes(stock.id)}
        order={this.props.order}
        margin={this.props.margin}
        stock={stock}
        canPlan={this.props.canPlan}
        canLock={this.props.canLock}
        onPlan={this.props.onPlan}
        onUnplan={this.props.onUnplan}
        onLock={this.props.onLock}
        onUnlock={this.props.onUnlock}
        depots={this.props.depots}
      />
    );
  },

  render () {
    if (!this.props.initialized) {
      return null;
    }

    return (
      <div className="gantt-full">
        <Error error={this.props.error} />
        {this.props.orderChanged && (
          <div className="flash notice">
            <strong>{I18n.t('javascripts.look_out')}</strong>
            {' '}
            {I18n.t('javascripts.planner.other_employee_warning', { employee_name: this.props.plannedByEmployee?.name || I18n.t('javascripts.someone') })}
          </div>
        )}
        <Calendar {...this.props} stockActions={this.renderStockActions}>
          <HighlightPeriod
            order={this.props.order}
            periodFrom={this.props.from}
            periodTill={this.props.till}
            from={this.props.order.pickup_at}
            till={this.props.order.return_at}
          />
        </Calendar>
      </div>
    );
  }
});

const mapStateToProps = (state, props) => {
  const order = state.orm.orders.getById(state.planner.orderID);

  if (order) {
    let orderChanged;
    const plannings = state.orm.plannings.getAll({ order_id: order.id });
    const plannedStockIDs = plannings.map(planning => planning.stock_id).toJS();

    if (props.employee_id !== order.last_planned_by) {
      if (order.last_planned_by !== state.planner.lastPlannedBy || moment(order.last_planned_at).format() !== moment(state.planner.lastPlannedAt).format()) {
        orderChanged = true;
      }
    }

    return {
      initialized: true,
      from: state.calendar.from,
      till: state.calendar.till,
      order,
      orderChanged,
      plannedByEmployee: state.orm.employees.getById(order.last_planned_by),
      orderID: order.id,
      plannedStockIDs,
      canPlan: state.planner.canPlan,
      canLock: state.planner.canLock,
      margin: state.planner.margin,
      error: state.planner.error
    };
  } else {
    return {};
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onMount () {
      PlannerActions.initialize(props);
      return Model.Employee.actions.fetchAll();
    },
    onUnMount () {
      return PlannerActions.teardown(props);
    },
    onPlan (stock, order) {
      return PlannerActions.plan(stock, order);
    },
    onUnplan (planning) {
      return StockPlanningActions.delete({ id: planning.id });
    },
    onLock (planning) {
      return StockPlanningActions.lock({ id: planning.id });
    },
    onUnlock (planning) {
      return StockPlanningActions.unlock({ id: planning.id });
    }
  };
};

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(PlannerScene);
