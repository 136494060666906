import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectOrderById } from 'mobile/redux/selectors/ormSelectors';

import CartProducts from './OrderProducts/CartProducts';
import PlanningProducts from './OrderProducts/PlanningProducts';

const OrderProducts = React.memo((
  {
    cart_products,
    days_charged,
    no_sales,
    order_id
  }
) => {
  const [showOnlyWebshop, setShowOnlyWebshop] = useState(false);
  const order = useSelector(state => selectOrderById(state, order_id));

  const handleShowOnlyWebshopChange = useCallback(() => {
    setShowOnlyWebshop(!showOnlyWebshop);
  }, [showOnlyWebshop]);

  return (
    <div className="order-products divider">
      <div className="title">
        {I18n.t('javascripts.order.products_sidebar.title')}
      </div>
      {order.webshop_order && (
        <>
          <p className="charge">
            <strong>{I18n.t('javascripts.order.products_sidebar.days_amount')}</strong>
            {days_charged}
          </p>
          <div className="checkbox-container my-2">
            <label>
              <input
                type="checkbox"
                className="ml-0"
                checked={showOnlyWebshop}
                onChange={handleShowOnlyWebshopChange}
                data-testid="show-only-webshop"
              />
              {I18n.t('javascripts.order.products_sidebar.show_only_webshop')}
            </label>
          </div>
        </>
      )}
      <ul>
        <CartProducts
          cart_products={cart_products}
          no_sales={no_sales}
          order_id={order_id}
        />
        {!showOnlyWebshop && <PlanningProducts order_id={order_id}/>}
      </ul>
    </div>
  );
});

OrderProducts.displayName = 'OrderProducts';

export default OrderProducts;
