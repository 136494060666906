import React, { Component } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';

import { groupDepots } from 'client/utils/depots';

import Spinner from './Spinner';

/**
 * Specify order pickup and return time and location.
 *
 * @example
 *   <LocationDatePicker
 *     date={date}
 *     timeslots={timeslots}
 *     depots={depots}
 *     onPickDate={this.handleChangeDate}
 *     onPickTimeslot={this.handleChangeTime}
 *     onPickDepot={this.handleChangeDepot} />
 */

class LocationDatePicker extends Component {
  static displayName = 'LocationDatePicker';

  handleClickDepot = (depot) => () => {
    this.props.onPickDepot(depot);
  };

  handleClickTimeslot = (timeslot) => () => {
    this.props.onPickTimeslot(timeslot);
  };

  renderTimeslot = (timeslot) => {
    const classNames = [];
    const selected = this.props.selectedTimeslot && timeslot.id === this.props.selectedTimeslot.id;

    if (selected) {
      classNames.push('location-date-picker-time__option--selected');
    }

    if (this.props.isTimeslotDisabled(timeslot)) {
      classNames.push('location-date-picker-time__option--disabled');
    }

    return (
      <a
        key={timeslot.id}
        className={`location-date-picker-time__option ${classNames.join(' ')}`}
        onClick={this.handleClickTimeslot(timeslot)}
      >
        {timeslot.label}
      </a>
    );
  };

  renderDepot = (depot) => {
    const classNames = [depot.color_class];
    const selected = depot.id === this.props.selectedDepot.id;

    if (selected) {
      classNames.push('location-date-picker-location__option--selected');
      classNames.push('color');
    }

    if (this.props.isDepotDisabled(depot)) {
      classNames.push('location-date-picker-location__option--disabled');
    }

    return (
      <a
        key={depot.id}
        className={`location-date-picker-location__option border-color ${classNames.join(' ')}`}
        onClick={this.handleClickDepot(depot)}>
        {depot.name}
      </a>
    );
  };

  renderDepotGroup = (group) => {
    const { domain, shops, pickups } = group;

    return (
      <div key={domain} className="location-date-picker-location__group">
        <div className="location-date-picker-location__group-title">{domain}</div>
        <div className="location-date-picker-location__group-body">
          <div className="location-date-picker-location__divider">Shops</div>
          {shops.map(this.renderDepot)}
          <div className="location-date-picker-location__divider">Pickup points</div>
          {pickups.map(this.renderDepot)}
        </div>
      </div>
    );
  };

  render () {
    const { depots, timeslots, date, range, onPickDate, isDayDisabled, orientation, isSubmitting } = this.props;
    const groupedDepots = groupDepots(depots);

    const classNames = ['location-date-picker'];

    switch (orientation) {
    case 'right':
      classNames.push('location-date-picker--right');
      break;
    case 'bottom':
      classNames.push('location-date-picker--bottom');
      break;
    }

    return (
      <div className={classNames.join(' ')}>
        {isSubmitting && <Spinner />}
        <div className="location-date-picker-location">
          {groupedDepots.map(this.renderDepotGroup)}
        </div>
        <div className="location-date-picker-date">
          <DayPicker
            onDayClick={onPickDate}
            defaultMonth={date}
            selected={date}
            modifiers={{ highlighted: range, start: range.from, end: range.to }}
            modifiersStyles={{
              today: {
                color: 'white',
                fontWeight: 700
              },
              highlighted: {
                backgroundColor: '#4b5563'
              },
              start: {
                backgroundColor: '#0284c7'
              },
              end: {
                backgroundColor: '#0284c7'
              }
            }}
            disabled={isDayDisabled}
            firstDayOfWeek={1}
            showOutsideDays
            ISOWeek
          />
        </div>
        <div className="location-date-picker-time">
          {timeslots.sort('from_time_integer').map(this.renderTimeslot)}
        </div>
      </div>
    );
  }
}

export default LocationDatePicker;
