const React = require('react');
const createReactClass = require('create-react-class');

const DetachedComponent = require('client/react/containers/detached_component');

const Popup = createReactClass({
  componentDidMount () {
    return $(document).on('keyup', this.handleKeyPress);
  },

  componentWillUnmount () {
    return $(document).off('keyup', this.handleKeyPress);
  },

  handleClickContainer (event) {
    const target = $(event.relatedTarget || event.target);

    if (target.is('#popup-container') || target.is('.modal')) {
      this.props.onClickOutside?.(event);
    }
  },

  handleKeyPress (event) {
    if (!this.props.shown) {
      return;
    }

    if (event.key === 'Escape' && this.props.onClickOutside) {
      return this.props.onClickOutside(event);
    }
  },

  renderPopup () {
    if (!this.id) {
      this.id = createId();
    }

    if (this.props.style === 'v2') {
      return (
        <div
          key={`popup-${this.id}`}
          className="modal"
          onClick={this.handleClickContainer}
          onKeyUp={this.handleKeyPress}
        >
          <div className={`popup ${this.props.className || ''}`}>
            <div className="inner">
              {this.props.children}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          key={`popup-${this.id}`}
          id="popup-container"
          onClick={this.handleClickContainer}
          onKeyUp={this.handleKeyPress}
        >
          <div id="popup-wrapper">
            <div id="popup-content">
              {this.props.children}
            </div>
          </div>
        </div>
      );
    }
  },

  render () {
    if (!this.id) {
      this.id = createId();
    }

    const popup = this.props.shown ? this.renderPopup() : null;

    return <DetachedComponent>{popup}</DetachedComponent>;
  }
});

module.exports = Popup;
