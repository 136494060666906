import 'client/redux/actions/stock';

import consumer from './consumer';

document.addEventListener('turbo:load', () => {
  if (window.appType === 'Roots') {
    consumer.subscriptions.create(
      { channel: 'StockPrintChannel', employee_id: $('body').data('employeeId') },
      {
        received (data) {
          const isEligibleForPrint = window.isMainTab && isMainTab();
          if (!isEligibleForPrint) return;

          Model.Stock.actions.fetchAll({ id: data.stock_ids }).then((action) => {
            const stocks = action.payload.response.stocks;

            stocks.forEach((stock) => {
              // eslint-disable-next-line promise/no-nesting
              QRCodePrinter.printStock(stock, { size: data.size || 'small' }).then((result) => {
                if (result) {
                  Model.Stock.actions.trackQrPrint({ stock_id: stock.id });
                }
              });

              if (data.is_new && stock.product_type === 'bag') {
                // Print another sticker, bags require 2 stickers
                QRCodePrinter.printStock(stock, { size: data.size || 'small' });
              }
            });
          });
        }
      }
    );
  }
});
