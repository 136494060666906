const React = require('react');
const createReactClass = require('create-react-class');

const CustomerSegment = require('client/react/components/customer_segment');
const { twilioCall } = require('./utils');

/* eslint-disable react/jsx-no-bind */
const Recent = createReactClass({
  render () {
    return (
      <div>
        <div className="recent">
          <ul className="list">
            {this.props.phoneCalls.map(phoneCall => {
              let direction;
              const customer = phoneCall.getCustomer();
              const domain = this.props.domains.getById(phoneCall.domain_id);

              switch (phoneCall.direction) {
              case 'inbound':
                direction = 'arrow-left';
                break;
              case 'outbound':
                direction = 'arrow-right';
                break;
              case 'internal':
                direction = 'retweet';
                break;
              }

              return (
                <li key={phoneCall.id}>
                  <div className="segment">
                    <CustomerSegment customer={customer}/>
                    <i className={`fa fa-${direction}`}/>
                  </div>

                  <div className="content">
                    <span className={`customer-name customer_status status ${customer?.status?.color}`}>
                      {phoneCall.customer_name}
                    </span>
                    <span className="phone-number">
                      {phoneCall.employee_name || I18n.t('javascripts.phone_bar.no_answer')}
                    </span>

                    <div className="locale-domain"><span
                      className="domain">{domain?.name}</span>
                    <span className="locale">{phoneCall.locale?.toUpperCase()}</span>
                    </div>

                    <div className="time">
                      <i className="fa fa-clock-o"/>
                      {moment(phoneCall.created_at).format('HH:mm')}
                    </div>

                    {phoneCall.direction !== 'internal' && (
                      <div className="buttons">
                        {phoneCall?.number && (
                          <a
                            className="button small"
                            onClick={this.props.call.bind(null, phoneCall)}
                          >
                            <i className="fa fa-phone"/>
                            {I18n.t('javascripts.phone_bar.call')}
                          </a>
                        )}

                        {customer && (
                          <a
                            className="button small"
                            href={`/customers/${customer.id}/orders`}
                          >
                            <i className="fa fa-calendar-check-o"/>
                            {I18n.t('javascripts.phone_bar.view_reservations')}
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
});
/* eslint-enable react/jsx-no-bind */

const mapStateToProps = (state, _props) => {
  const phoneCalls = state.orm.phone_calls
    .sortBy(phoneCall => -phoneCall.id)
    .toList()
    .take(15);

  return {
    phoneCalls,
    domains: state.orm.domains
  };
};

const mapDispatchToProps = (_dispatch, _phoneCall) => {
  return {
    call (phoneCall) {
      return twilioCall({ To: phoneCall.number });
    }
  };
};

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Recent);
