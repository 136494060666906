const React = require('react');
const createReactClass = require('create-react-class');

const PhoneClientActions = require('client/redux/actions/phone_client');
const PhoneCallActions = require('client/redux/actions/phone_call');
const EmployeeActions = require('client/redux/actions/employee');
const DomainActions = require('client/redux/actions/domain');
const CallbackRequestActions = require('client/redux/actions/callback_request');
const callbackRequestSelectors = require('client/react/selectors/callback_requests');

const ReactTabs = require('react-tabs');
const Dialer = require('./dialer');
const Queue = require('./queue');
const CallControls = require('./call_controls');
const Customer = require('./customer');
const Orders = require('./orders');
const ReturnForms = require('./return_forms');
const History = require('./history');
const Recent = require('./recent');
const Notes = require('client/react/components/notes');
const CallbackRequests = require('./callback_requests');
const EmployeeFunction = require('./employee_function.jsx').default;
const DomainSelect = require('./domain_select.jsx').default;
const LanguageSelect = require('./language_select.jsx').default;
const WrapUp = require('./wrap_up');

/* eslint-disable no-console */
const setAudioDevice = () => {
  Twilio.Device.audio.availableInputDevices.forEach((device, id) => {
    if (device.label === 'Sennheiser SC660 Control' || device.label === 'Sennheiser D 10' || device.label === 'Sennheiser D 10 for Lync' || device.label === 'Logitech USB Headset H340' || device.label === 'Sennheiser SC660 for Lync') {
      // eslint-disable-next-line promise/no-nesting
      return Twilio.Device.audio.unsetInputDevice().then(() => Twilio.Device.audio.setInputDevice(id).then(() => {
        console.info(`- Setting input device: ${device.label}`);
        window.headsetInput = device.label;
      }));
    }
  });

  Twilio.Device.audio.availableOutputDevices.forEach((device, id) => {
    if (device.label === 'Sennheiser SC660 Control' || device.label === 'Sennheiser D 10' || device.label === 'Sennheiser D 10 for Lync' || device.label === 'Logitech USB Headset H340' || device.label === 'Sennheiser SC660 for Lync') {
      return Twilio.Device.audio.speakerDevices.set(id).then(() => {
        console.info(`- Setting output device: ${device.label}`);
        window.headsetOutput = device.label;
      });
    }
  });
  // Make sure we return
  return true;
};

const PhoneBar = createReactClass({
  componentDidMount () {
    this.props.fetchToken();
    this.props.fetchQueued();
    this.props.fetchRecent();
    this.props.fetchOnlineEmployees();
    this.props.fetchDomains();

    CallbackRequestActions.fetchAll();

    if (!$('body').data('openPhoneBar')) {
      PhoneClientActions.close();
    }

    if ($('.body-container').hasClass('with-notice-bar')) {
      this.style = { paddingTop: '45px' };
    }

    if ($('.body-container').hasClass('with-notice-bar')) {
      this.buttonStyle = { marginTop: '34px' };
    }
  },

  componentDidUpdate () {
    this.props.initClient();
  },

  render () {
    return (
      <div id="phone-bar" className={this.props.openClass}>
        {
          !['inbound', 'overflow', 'ehbp'].includes(this.props.function) && (
            <a
              className="phone-bar-toggler"
              onClick={this.props.toggle}
              style={this.buttonStyle}
            >
              <i className="fa fa-phone"/></a>
          )
        }

        <div className="inner" style={this.style}>
          {
            this.props.status === 'ready' && (
              <div>
                <DomainSelect domains={this.props.domains}/>
                <LanguageSelect/>
                <EmployeeFunction employeeId={this.props.employeeId}/>
                <Dialer/>
                <ReactTabs.Tabs>
                  <ReactTabs.TabList>
                    <ReactTabs.Tab>
                      {I18n.t('javascripts.phone_bar.queues')}
                    </ReactTabs.Tab>
                    <ReactTabs.Tab>
                      {I18n.t('javascripts.phone_bar.call_back', { count: this.props.callbackRequests.size() })}
                    </ReactTabs.Tab>
                    <ReactTabs.Tab>
                      {I18n.t('javascripts.phone_bar.recent')}
                    </ReactTabs.Tab>
                  </ReactTabs.TabList>

                  <ReactTabs.TabPanel>
                    <Queue/>
                  </ReactTabs.TabPanel>

                  <ReactTabs.TabPanel>
                    <CallbackRequests callbackRequests={this.props.callbackRequests}/>
                  </ReactTabs.TabPanel>

                  <ReactTabs.TabPanel>
                    <Recent/>
                  </ReactTabs.TabPanel>
                </ReactTabs.Tabs>
              </div>
            )
          }

          {this.props.status === 'wrapping_up' && <WrapUp/>}
          {
            (this.props.status === 'busy' || this.props.status === 'redirecting') && (
              <CallControls phoneCall={this.props.phoneCall}/>
            )
          }
          {this.props.phoneCall && (this.props.status === 'busy' || this.props.status === 'wrapping_up') && (
            <div>
              <Customer phoneCall={this.props.phoneCall}/>

              {this.props.phoneCall.customer && (
                <ReactTabs.Tabs>
                  <ReactTabs.TabList>
                    <ReactTabs.Tab>
                      {I18n.t('javascripts.phone_bar.recent')}
                    </ReactTabs.Tab>
                    <ReactTabs.Tab>
                      {I18n.t('javascripts.phone_bar.notes')}
                    </ReactTabs.Tab>
                    <ReactTabs.Tab>
                      {I18n.t('javascripts.phone_bar.history')}
                    </ReactTabs.Tab>
                  </ReactTabs.TabList>

                  <ReactTabs.TabPanel>
                    <Orders phoneCall={this.props.phoneCall}/>
                    <ReturnForms phoneCall={this.props.phoneCall}/>
                  </ReactTabs.TabPanel>

                  <ReactTabs.TabPanel>
                    <Notes
                      notes={this.props.notes}
                      notableType="Customer"
                      notableId={this.props.phoneCall.customer}
                      employeeId={this.props.employeeId}
                    />
                  </ReactTabs.TabPanel>

                  <ReactTabs.TabPanel>
                    <History phoneCall={this.props.phoneCall}/>
                  </ReactTabs.TabPanel>
                </ReactTabs.Tabs>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
});

const mapStateToProps = (state, _props) => {
  let openClass;
  const client = state.phoneClient;

  const phoneCall = state.orm.phone_calls.filter((phoneCall) => {
    if (!client.callSid) {
      return false;
    }
    return phoneCall.sid === client.callSid || phoneCall.client_sid === client.callSid;
  }).toList().first();

  const customer = state.orm.customers.getById(phoneCall?.customer);

  let notes = state.orm.notes.filter((note) => {
    if (note.notable_type === 'Customer' && note.notable_id === customer?.id) {
      return true;
    }
    if ($.inArray(note.id, customer?.notes || []) !== -1) {
      return true;
    }
    return false;
  });

  notes = notes.sortBy(note => -note.id);

  let callbackRequests = callbackRequestSelectors.forEmployee(state.orm.callback_requests, {
    employeeId: client.employee_id
  });

  callbackRequests = callbackRequestSelectors.notDone(callbackRequests);
  callbackRequests = callbackRequestSelectors.forNow(callbackRequests);
  callbackRequests = callbackRequestSelectors.sortedByScheduled(callbackRequests);

  if (phoneCall || ['inbound', 'overflow', 'ehbp'].includes(client.function) || client.open) {
    $('.body-container').addClass('has-phone-bar');
    openClass = 'open';
  } else {
    $('.body-container').removeClass('has-phone-bar');
    openClass = 'closed';
  }

  return {
    ready: client.ready,
    status: client.status,
    token: client.token,
    identity: client.identity,
    function: client.function,
    phoneCall,
    callSid: client.callSid,
    employeeId: client.employee_id,
    notes,
    callbackRequests,
    openClass,
    domains: state.orm.domains
  };
};

const mapDispatchToProps = (_dispatch, _props) => {
  return {
    initClient () {
      let client = store.getState().phoneClient;

      if (!window.initializedTwilio && !client.ready && client.token) {
        console.info('Initializing Twilio handlers');
        window.initializedTwilio = true;

        PhoneClientActions.setClientReady(); // So we don't initialize twice

        // Get client data
        client = store.getState().phoneClient;

        // Setup Twilio Client
        PhoneClientActions.setupTwilioClient(client.token);

        // Let our UI know we're ready
        Twilio.Device.ready(() => {
          console.info('Twilio device ready');

          // Set Device
          setAudioDevice();

          // Set back to wrapping up if user still is (could be user refreshed window)
          client = store.getState().phoneClient;
          if (client.busy) {
            return PhoneClientActions.setStatus('wrapping_up');
          } else {
            return PhoneClientActions.setStatus('ready');
          }
        });

        Twilio.Device.offline(() => {
          console.info('Twilio device offline');

          return PhoneClientActions.fetchToken().then(() =>
            // Setup Twilio Client
            PhoneClientActions.setupTwilioClient(store.getState().phoneClient.token));
        });

        Twilio.Device.audio.on('deviceChange', () => {
          console.info('Device change');
          const isBusy = store.getState().phoneClient.status === 'busy';

          // Do not set device while in call
          if (isBusy) {
            return true;
          } else {
            return setAudioDevice();
          }
        });

        // Always accept incoming connections
        Twilio.Device.incoming((connection) => {
          console.info('Incoming connection');

          return connection.accept();
        });

        Twilio.Device.error((error) => {
          console.error(`Twilio.Device Error: ${error.message}`);

          return true;
        });

        // Register callback function for when we're connected
        Twilio.Device.connect((connection) => {
          // Mark employee as busy
          PhoneClientActions.setBusy(client.employee_id);

          if (connection._direction !== 'INCOMING') {
            const sid = connection.parameters.CallSid;
            PhoneClientActions.connect(sid);
          }

          console.info(`Connected phone call at: ${new Date()}`);
          console.info('- Input device:', Twilio.Device.audio.inputDevice?.label);

          return connection.on('warning', message => console.warn(message));
        });

        // Register callback for when either party hangs up
        return Twilio.Device.disconnect(() => {
          console.info('Disconnected phone call');

          // Check if phone call was bridged
          client = store.getState().phoneClient;
          const wasBridged = store.getState().orm.phone_calls.filter((phoneCall) => {
            return phoneCall.sid === client.callSid || phoneCall.client_sid === client.callSid;
          }).toList().first()?.was_bridged;

          PhoneClientActions.disconnect(wasBridged);

          // Call was never bridged, make sure we wrap up
          if (!wasBridged) {
            PhoneClientActions.wrappedUp(client.employee_id);
          }

          return setAudioDevice();
        });
      }
    },

    fetchToken () {
      return PhoneClientActions.fetchToken();
    },

    fetchQueued () {
      return PhoneCallActions.fetchQueued();
    },

    fetchRecent () {
      return PhoneCallActions.fetchAll();
    },

    fetchOnlineEmployees () {
      return EmployeeActions.fetchOnline();
    },

    fetchDomains () {
      return DomainActions.fetchAll();
    },

    toggle () {
      const { open } = store.getState().phoneClient;

      if (open) {
        return PhoneClientActions.close();
      } else {
        return PhoneClientActions.open();
      }
    }
  };
};
/* eslint-enable no-console */

module.exports = ReactRedux.connect(mapStateToProps, mapDispatchToProps)(PhoneBar);
