import React from 'react';
import classNames from 'classnames';

const CartProductColumns = React.memo(
  ({
    cart_product,
    plannedQuantity,
    allPlanned,
    hasLimitedAvailability
  }) => {
    return (
      <>
        <span className="icon col-2 pl-0 pr-0 mr-0 text-center">
          <i
            className={classNames(
              'fa-solid fa-cart-shopping',
              {
                'text-success': allPlanned,
                'text-danger': hasLimitedAvailability
              }
            )}
          />
        </span>
        <span
          className={classNames('name col pr-1 pl-0', { 'text-danger': hasLimitedAvailability })}
          title={cart_product.product_name}
        >
          {cart_product.product_name}
        </span>
        <span
          className={classNames(
            'quantities col-3 pl-0',
            {
              'text-success': allPlanned,
              'text-danger': hasLimitedAvailability
            }
          )}
        >
          {plannedQuantity}/{cart_product.qty}
        </span>
      </>
    );
  }
);

CartProductColumns.displayName = 'CartProductColumns';

export default CartProductColumns;
